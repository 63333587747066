import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosBaseQuery = async (args, api, extraOptions) => {
  const { url, method = "GET", body, headers } = args;
  const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;
  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: body,
      headers,
    });

    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

const voucherAPI = createApi({
  refetchOnMountOrArgChange: 10,
  reducerPath: "voucherApi",
  tagTypes: ["Voucher"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getAllVouchers: builder.query({
      query: () => ({
        url: `/voucher`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Voucher"],
    }),

    addVoucher: builder.mutation({
      query: (body) => ({
        url: `/voucher/create`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Voucher"],
    }),
    editVoucher: builder.mutation({
      query: ({ body, id }) => ({
        url: `/voucher/update/${id}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Voucher"],
    }),
    deleteVoucher: builder.mutation({
      query: ({ id }) => ({
        url: `/voucher/delete/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Voucher"],
    }),
    toggleActiveVoucher: builder.mutation({
      query: ({ id }) => ({
        url: `/voucher/toggleActive/${id}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Voucher"],
    }),
    addVoucherPin: builder.mutation({
      query: ( body ) => ({
        url: `/voucher/pin`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Voucher"],
    }),
    removePinVoucher: builder.mutation({
      query: (body) => ({
        url: `/voucher/remove-pin`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Voucher"],
    }),
    getNewPinnedVoucher: builder.query({
      query:() => ({
        url: `/voucher/new-pinned`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Voucher"],
    }),
    getHotPinnedVoucher: builder.query({
      query:() => ({
        url: `/voucher/hot-pinned`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Voucher"],
    }),
    getMostSoldPinnedVoucher: builder.query({
      query:() => ({
        url: `/voucher/most-sold-pinned`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Voucher"],
    }),
    addToProviderDepartment: builder.mutation({
      query: (body) => ({
        url: `/voucher/add-to-provider-department`,
        method: "POST",
        body,
        headers:{
          "Content-Type": "multipart/form-data",
        }
      }),
      invalidatesTags: ["Voucher"],
    })
  }),
});

export const {
  useGetAllVouchersQuery,
  useAddVoucherMutation,
  useEditVoucherMutation,
  useDeleteVoucherMutation,
  useToggleActiveVoucherMutation,
  useAddVoucherPinMutation,
  useGetNewPinnedVoucherQuery,
  useGetHotPinnedVoucherQuery,
  useGetMostSoldPinnedVoucherQuery,
  useRemovePinVoucherMutation,
  useAddToProviderDepartmentMutation
} = voucherAPI;
export default voucherAPI;
