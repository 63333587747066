import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosBaseQuery = async (args, api, extraOptions) => {
  const { url, method = "GET", body, headers } = args;
  const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;
  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: body,
      headers,
    });

    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

const giftAPI = createApi({
  refetchOnMountOrArgChange: 10,
  reducerPath: "giftApi",
  tagTypes: ["Gift"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getAllGifts: builder.query({
      query: () => ({
        url: `/gift`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Gift"],
    }),
    createGift: builder.mutation({
      query: (body) => ({
        url: `/gift`,
        method: "POST",
        body,
      }),
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      providesTags: ["Gift"],
    }),
    editGift: builder.mutation({
      query: ({ body, id }) => ({
        url: `/gift/update/${id}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Gift"],
    }),
    deleteGift: builder.mutation({
      query: ({ id }) => ({
        url: `/gift/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Gift"],
    }),
  }),
});

export const {
  useGetAllGiftsQuery,
  useCreateGiftMutation,
  useDeleteGiftMutation,
  useEditGiftMutation
} = giftAPI;
export default giftAPI;
