const TableHeaders = [
  {
    accessorKey: "voucher_share_points",
    header: "voucherSharePoints",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "created_at",
    header: "CreatedAt",
    type: "text",
    required: false,
    size: 140,
  },
];
export default TableHeaders;
