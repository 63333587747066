import { Chip, Switch, Typography } from "@mui/material";
import { t } from "i18next";
import { Check, Close } from "@mui/icons-material";

const TableChipCell = ({ row }) => {
  let color = "info";
  switch (row.original.status) {
    case "pending": {
      color = "warning";
      break;
    }
    case "accepted": {
      color = "success";
      break;
    }
    case "canceled": {
      color = "error";
      break;
    }
    default: {
      color = "info";
    }
  }
  return <Chip label={t(row.original.status)} color={color} variant="filled" />;
};
const TableClientNameCell = ({ row }) => {
  return <Typography>{row.original.client?.name}</Typography>;
};
const TableProviderNameCell = ({ row }) => {
  return <Typography>{row.original.provider?.name}</Typography>;
};
const TableBoolCell = ({ row }) => {
  return row.original.by_code ? (
    <Check color="success" />
  ) : (
    <Close color="error" />
  );
};

const TableHeaders = [
  {
    accessorKey: "order_number",
    header: "OrderNumber",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "provider_name",
    header: "ProviderName",
    type: "text",
    required: false,
    size: 140,
  },

  {
    accessorKey: "client_name",
    header: "ClientName",
    type: "text",
    required: false,
    size: 140,
  },

  {
    accessorKey: "total",
    header: "TotalCost",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "company_share",
    header: "CompanyShare",
    type: "text",
    required: false,
    size: 140,
  },

  {
    accessorKey: "by_code",
    header: "ByCode",
    type: "text",
    required: false,
    size: 140,
    Cell: TableBoolCell,
  },
  {
    accessorKey: "status",
    header: "Status",
    type: "text",
    required: false,
    size: 140,
    Cell: TableChipCell,
  },
];
export default TableHeaders;
