const TableHeaders = [
  {
    accessorKey: "name",
    header: "Name",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "description",
    header: "Description",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "count",
    header: "Count",
    type: "text",
    required: false,
    size: 140,
  },

  {
    accessorKey: "required_points",
    header: "RequiredPoints",
    type: "text",
    required: false,
    size: 140,
  },

  {
    accessorKey: "created_at",
    header: "CreatedAt",
    type: "text",
    required: false,
    size: 140,
  },
];
export default TableHeaders;
