import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Paper, Badge } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ClearIcon from '@mui/icons-material/Clear';
import ImageContainer from './ImageContainer';

const ImageGrid = ({data,handleChanges}) => {
  const [images, setImages] = useState(data??[]);
  const [files, setFiles] = useState([]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setImages([...images, imageUrl]);
    setFiles([...files, file]);
    handleChanges([...files, file])
    
  };
  
  const handleRemoveImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    const updatedFiles = files.filter((_, i) => i !== index);
    setImages(updatedImages);
    setFiles(updatedFiles);
    handleChanges(updatedFiles)
  };



  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <label htmlFor="image-upload">
          <IconButton color="primary" component="span">
            <AddPhotoAlternateIcon fontSize="large" />
          </IconButton>
        </label>
        <input
          type="file"
          accept="image/*"
          id="image-upload"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
      </Grid>
      {images.map((image, index) => (
        <Grid item xs={4} key={index}>
          <Paper elevation={0}>
            <Badge
            sx={{padding:"0" }}
              badgeContent={
                <IconButton
                  size="small"
                  onClick={() => handleRemoveImage(index)}
                >
                  <ClearIcon  sx={{color:"#fff"}} />
                </IconButton>
              }
              overlap="circular"
              color="error"
      
            >
              <ImageContainer url={typeof image === 'object' ? image.image_url : image}/>
            </Badge>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default ImageGrid;
