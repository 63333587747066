import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import {
  setDeleteMode,
  setEditMode,
  setItemToDelete,
  setItemToEdit,
  setItemToView,
  setViewMode,
} from "../../data/slices/adminSlice";

function TableActions(row) {
  const dispatch = useDispatch();
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}>
        {row.original.type !== "super" && (
          <Tooltip arrow placement="left" title={t("Delete")}>
            <IconButton
              onClick={() => {
                dispatch(setItemToDelete(row.original));
                dispatch(setDeleteMode(true));
              }}
            >
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </>
  );
}

export default TableActions;
