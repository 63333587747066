import { useMemo } from 'react';
import { t } from 'i18next';
import TableHeaders from './tableHeaders';
import Loader from '../../components/Common/Loader';
import { useGetOrderReportMutation } from '../../data/api/home';

import ReportFilter from './reportFilter';
import AwesomeExportTable from '../../components/Awesome/AweomeExportTable';
import { Box, Typography } from '@mui/material';
import ProgressCircle from '../../../components/ProgressCircle';
import LineChart from '../dashboard/lineChart';
import convertToChartData from '../dashboard/convertChartData';
import { tokens } from '../../../theme';
import { useTheme } from '@emotion/react';
import exportToPDF from '../../utils/exportToPDF';

function ReportsPage() {
  const [getOrderReport, getOrderReportResult] = useGetOrderReportMutation();
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const columns = useMemo(
    () =>
      TableHeaders.map((header) => ({
        ...header,
        header: t(header.header),
      })),
    []
  );

  const onSubmit = (body) => {

      getOrderReport(body);
   
  };
  const exportPDF = () => {

      exportToPDF("orders-report");
   
  };



  return (
    <Box  sx={{height:"90%", overflow:'scroll'}}>
      <ReportFilter onSubmit={onSubmit}/>
      {getOrderReportResult.isLoading? (<Loader/>):(
        getOrderReportResult.data &&
        <Box   >
           <AwesomeExportTable 
           
            columns={columns}
            data={getOrderReportResult.data.orders_table}
            showActions={false}
            showTopActions 
            exportPDF={exportPDF}
            />
            <div id="orders-report">

          <Box sx={{display:"flex", flexDirection:"row" ,justifyContent:"space-between"}}>
          <Typography
          p={2}
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
               {"المبيعات"}: {new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(getOrderReportResult.data?.total) }
           </Typography>
          <Typography
          p={2}

                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
               {"حصة الشركة"}: {new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(getOrderReportResult.data?.company_share) }
           </Typography>
          </Box>
          <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >

          <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
             
              {t("Invoices")}

              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
              </Typography>
            </Box>
        
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart data={convertToChartData( getOrderReportResult.data?.invoices_daily_chart)} isDashboard={true} />
          </Box>
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              
              {t("RecentOrders")}
            </Typography>
          </Box>
   
          <ProgressCircle progress={getOrderReportResult.data.company_share_ratio}/>

        </Box>
        </Box>
        </div>

        </Box>
           
      
      )}


    </Box>
  );
}

export default ReportsPage;
