import { useMemo } from "react";
import AwesomeTable from "../../components/Awesome/AweomeTable";
import mapToFormData from "../../utils/mapToFormData";
import { t } from "i18next";
import TableHeaders from "./tableHeaders";
import Loader from "../../components/Common/Loader";
import TableActions from "./tableActions";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";
import {
  setAddMode, setItemToAccept, setAcceptMode, setCancelMode, setItemToCancel, setItemToView, setViewMode } from "../../data/slices/orderGiftSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllOrderGiftQuery,
  useAcceptOrderGiftMutation,
  useCancelOrderGiftMutation,
} from "../../data/api/orderGift";
import OrderInvoice from './OrderInvoices';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useTheme } from '@emotion/react';
import AwesomeDialog from "../../components/Awesome/AwesomeDialog";

function OrderGiftPage() {
  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError, error } =
  useGetAllOrderGiftQuery();
  const { itemToAccept, itemToCancel, acceptMode, cancelMode, itemToView, viewMode } =
    useSelector((state) => state.orderGift);

    const [acceptOrderGift, acceptOrderGiftResult] = useAcceptOrderGiftMutation();
    const [ CancelOrderGift, CancelOrderGiftResult] = useCancelOrderGiftMutation();

    const theme = useTheme();
  const columns = useMemo(
    () =>
      TableHeaders.map((header) => ({
        ...header,
        header: t(header.header),
      })),
    []
  );
  const setCreateOptions = () => {
    dispatch(setAddMode(true));
  };
  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }

  return (
    <>
      {data && (
        <AwesomeTable
          columns={columns}
          data={data.data}
          showActions ={false}
          RowActions={TableActions}
          showTopActions ={false}
        />
      )}

      <ConfirmationDialog
        open={acceptMode}
        onClose={() => {
          dispatch(setItemToAccept(null));
          dispatch(setAcceptMode(false));
        }}
        onConfirm={() => {
          acceptOrderGift({ id: itemToAccept.id });
        }}
        message={t("AcceptOrderGiftMessage")}
      />

      <ConfirmationDialog
        open={cancelMode}
        onClose={() => {
          dispatch(setItemToCancel(null));
          dispatch(setCancelMode(false));
        }}
        onConfirm={() => {
          CancelOrderGift({ id: itemToCancel.id });
        }}
        message={t("CancelOrderGiftMessage")}
      />

      <Dialog open={viewMode} dir={theme.direction} >
        <DialogTitle textAlign="center">{}</DialogTitle>
          <DialogContent>
            <Stack
              sx={{
                width: '100%',
                minWidth: { xs: '400px', sm: '460px', md: '500px' },
                gap: '1.5rem',
              }}
            >

            <OrderInvoice  order={itemToView}  />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button color='inherit' onClick={() => {
              dispatch(setItemToView(null))
              dispatch(setViewMode(false))
            }} >
              {t("Close")}
            </Button>

        </DialogActions>
      </Dialog>
    </>
  );
}

export default OrderGiftPage;
