import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import AwesomeTable from '../../components/Awesome/AweomeTable';
import { Box, Button, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Lock, LockOpen } from '@mui/icons-material';
import { setAddMode, setEditMode, setItemToEdit, setItemToView, setViewMode } from '../../data/slices/accountingSlice';

import AddPaymentDialog from './AddPaymentDialog';
import { t } from 'i18next';
import { useAddPayOffMutation, useGetRequiredMoneyFromAllProvidersQuery } from '../../data/api/accounting';
import Loader from '../../components/Common/Loader';
import TableHeaders from './tableHeaders';

function AccountingsPage() {
 

  const [selectedRow, setSelectedRow] = useState();
  const [openView, setOpenView] = useState(false);
  

  const {itemToView,viewMode,addMode,deleteMode,editMode,itemToEdit} = useSelector(state=> state.accounting);

  const { data, isLoading, isFetching, isError, error ,...props} = useGetRequiredMoneyFromAllProvidersQuery();

  const [createPayoffPayment, createPayoffPaymentResult] = useAddPayOffMutation();

  const onSubmit = (body) => {
    var dto={
      amount : body.amount,
      type: selectedRow.type,
      provider_id: selectedRow.id
    }
    createPayoffPayment(dto);
  };
  if(createPayoffPaymentResult.isSuccess){
    createPayoffPaymentResult.reset()
    props.refetch();
  }
  const columns = useMemo(
    () =>  TableHeaders.map((header) => ({
      ...header,
      header: t(header.header), 
    })),
    [],
  );
 
  const setPaymentOptions=(value)=>{
    setSelectedRow(value);
    setOpenView(true);
  }
  const RowActions =(row)=>{
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>

          <Tooltip arrow placement="left" title={t("AddPayoff")}>
            <Button color="success" variant="contained" onClick={() => {
              
              setPaymentOptions(row.original); 
              
              }}>
                {t("AddPayoff")}
            </Button>
          </Tooltip>
       
        </Box>
      </>
    );
  }
  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }

  return (
    <>


    {data && <AwesomeTable 
      columns={columns}
      data={data}
      showActions ={false}
      RowActions={RowActions}
      showTopActions ={false}
      />}

  
   <AddPaymentDialog
      title={t("AddPayoff")}
      columns={[{accessorKey:"amount"}]}
      open={openView}
      onSubmit={onSubmit}
      
      onClose={() => {
        setOpenView(false)
        setSelectedRow();
        
      }}
    />

    {/* <ConfirmationDialog
      open={confirmationOpen}
      onClose={handleCloseConfirmation}
      onConfirm={setAcceptOptions}
      message="Are you sure you want to accept this request?"
      /> */}
      
    </>
  )
}

export default AccountingsPage