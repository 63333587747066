import ImageContainer from "../../components/Awesome/ImageContainer";

const TableImageCell = ({ row }) => {
  return <ImageContainer url={row.original.image} />;
};
const TableIconCell = ({ row }) => {
  return <ImageContainer url={row.original.icon} />;
};

const TableHeaders = [
  {
    accessorKey: "name",
    header: "Name",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "image",
    header: "Image",
    type: "image",
    required: false,
    size: 140,
    Cell: TableImageCell,
  },
  {
    accessorKey: "icon",
    header: "Icon",
    type: "image",
    required: false,
    size: 140,
    Cell: TableIconCell,
  },
];
export default TableHeaders;
