function formatDate(inputDate) {
  // Split the input date string by '/'
  const parts = inputDate.split("/");

  // Ensure we have three parts (month, day, year)
  if (parts.length === 3) {
    const month = parts[0];
    const day = parts[1];
    const year = parts[2];

    // Use template literals to format the date as 'YYYY-MM-DD'
    const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;

    return formattedDate;
  } else {
    // If the input format is incorrect, return an error message or handle it as needed
    return "Invalid date format";
  }
}

export default formatDate;
