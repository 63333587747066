import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

function ConfirmationDialog({ open, onClose, onConfirm, message }) {
  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{t("Confirmation")}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button color='inherit' onClick={handleCancel}>{t("Cancel")}</Button>
        <Button color="secondary" onClick={handleConfirm}>{t("Confirm")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
