import { Delete, BlockRounded, Edit, RemoveRedEye } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import {
  setEditMode,
  setItemToEdit,
  setItemToDelete,
  setItemToView,
  setViewMode,
  setDeleteMode,
} from "../../data/slices/clientSlice";

function TableActions(row) {
  const dispatch = useDispatch();
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}>
        <Tooltip arrow placement="left" title={t("View")}>
          <IconButton
            onClick={() => {
              dispatch(setItemToView(row.original));
              dispatch(setViewMode(true));
            }}
          >
            <RemoveRedEye color="warning" />
          </IconButton>
        </Tooltip>

        <Tooltip arrow placement="left" title={t("Delete")}>
          <IconButton
            onClick={() => {
              dispatch(setItemToDelete(row.original));
              dispatch(setDeleteMode(true));
            }}
          >
            <Delete color="error" />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
}

export default TableActions;
