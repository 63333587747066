
import { useMemo } from 'react';
import AwesomeTable from '../../components/Awesome/AweomeTable';

import { t } from 'i18next';
import TableHeaders from './tableHeaders';
import { useAddTermMutation, useDeleteTermMutation, useEditTermMutation, useGetAllTermsQuery } from '../../data/api/terms';
import Loader from '../../components/Common/Loader';
import TableActions from './tableActions';
import { setAddMode, setDeleteMode, setEditMode, setItemToDelete, setItemToEdit, setItemToView, setViewMode } from '../../data/slices/termSlice';
import { useDispatch, useSelector } from 'react-redux';
import FullScreenDialog from '../../components/Awesome/FullScreenDialog';
import AwesomeDialog from '../../components/Awesome/AwesomeDialog';
import mapToFormData from '../../utils/mapToFormData';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';

function TermsPage() {


  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError, error } = useGetAllTermsQuery();
  const {itemToView,viewMode,addMode,deleteMode,editMode,itemToEdit,itemToDelete} = useSelector(state=> state.term);
  const [createTerm, createTermResult] = useAddTermMutation();
  const [editTerm, editTermResult] = useEditTermMutation();
  const [deleteTerm, deleteTermResult] = useDeleteTermMutation();
  
  const columns = useMemo(
    () =>  TableHeaders.map((header) => ({
      ...header,
      header: t(header.header), 
    })),
    [],
  );


  const setCreateOptions = ()=>{
    dispatch(setAddMode(true))
  }
   const onSubmit = (body) => {
    body = mapToFormData(body);
    console.log(body)
    if (addMode) createTerm(body);
    if (editMode && itemToEdit) editTerm({ body, id: itemToEdit.id });
  };


  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }


  return (
    <>
    {data && <AwesomeTable 
    columns={columns}
    data={data}
    showActions
    RowActions={TableActions}
    setCreateOptions={setCreateOptions}
    showTopActions 
    />}




      <AwesomeDialog
      data = {itemToEdit}
      columns={[  
        { accessorKey: 'label',required:true, header: t('Label') },
        { accessorKey: 'value',required:true, header: t('Value') },
      ]}
      open={addMode || editMode}
      onClose={() => {
    
      dispatch(setEditMode(false))
      dispatch(setAddMode(false))
      dispatch(setItemToEdit(null))

      }}
      onSubmit={onSubmit}
    />
      <ConfirmationDialog
      open={deleteMode}
      onClose={()=>{
      dispatch(setItemToDelete(null))
      dispatch(setDeleteMode(false))

      }}
      onConfirm={()=>{
        deleteTerm({id:itemToDelete.id})
      }}
      message={t("DeleteConfirmation")}
      />

    </>
  )
}

export default TermsPage