import ImageContainer from "../../components/Awesome/ImageContainer";

const TableIconCell = ({ row }) => {
  return <ImageContainer url={row.original.icon} />;
};

const TableHeaders = [
  {
    accessorKey: "name",
    header: "Name",
    type: "text",
    required: false,
    size: 140,
  },

  {
    accessorKey: "icon",
    header: "Icon",
    type: "image",
    required: false,
    size: 140,
    Cell: TableIconCell,
  },
];
export default TableHeaders;
