import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import arLocale from "./locals/ar";
import enLocale from "./locals/en";
i18n.use(initReactI18next).init({
  lng: localStorage.getItem("language") || "en",
  resources: {
    en: {
      translation: enLocale,
    },
    ar: {
      translation: arLocale,
    },
  },

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
