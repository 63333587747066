const flattenObject = (obj, parentKey = "", sympol = ".") => {
  let result = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}${sympol}${key}` : key;

      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        const nestedObj = flattenObject(obj[key], newKey, sympol);
        result = { ...result, ...nestedObj };
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
};
export default flattenObject;
