import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosBaseQuery = async (args, api, extraOptions) => {
  const { url, method = "GET", body, headers } = args;
  const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;
  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: body,
      headers,
    });

    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

const providerAPI = createApi({
  refetchOnMountOrArgChange: 10,
  reducerPath: "providerApi",
  tagTypes: ["Provider"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getAllProviders: builder.query({
      query: () => ({
        url: `/provider`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Provider"],
    }),
    getProviderSelectList: builder.query({
      query: () => ({
        url: `/provider/selectList`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Provider"],
    }),

    addProvider: builder.mutation({
      query: (body) => ({
        url: `/provider/create`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Provider"],
    }),
    editProvider: builder.mutation({
      query: ({ body, id }) => ({
        url: `/provider/update/${id}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["Provider"],
    }),
    deleteProvider: builder.mutation({
      query: ({ id }) => ({
        url: `/provider/delete/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Provider"],
    }),
    toggleActiveProvider: builder.mutation({
      query: ({ id }) => ({
        url: `/provider/toggleActive/${id}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Provider"],
    }),
  }),
});

export const {
  useGetAllProvidersQuery,
  useGetProviderSelectListQuery,
  useAddProviderMutation,
  useEditProviderMutation,
  useDeleteProviderMutation,
  useToggleActiveProviderMutation,
} = providerAPI;
export default providerAPI;
