import { Chip, Switch } from "@mui/material";
import { t } from "i18next";
import { Check, Close } from "@mui/icons-material";
import { useToggleActiveCouponMutation } from "../../data/api/coupon";

const TableToggleCell = ({ row }) => {
  const [selectedCoupon, selectedCouponResult] =
    useToggleActiveCouponMutation();

  const handleChange = () => {
    selectedCoupon({ id: row.original.id });
  };

  return (
    <Switch
      color="secondary"
      checked={row.original.is_active}
      onChange={handleChange}
    />
  );
};

const TableHeaders = [
  {
    accessorKey: "code",
    header: "Code",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "provider_company_name",
    header: "Provider",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "amount",
    header: "Amount",
    type: "text",
    required: false,
    size: 140,
  },

  {
    accessorKey: "max_activation",
    header: "MaxActivation",
    type: "text",
    required: false,
    size: 140,
  },

  {
    accessorKey: "expiry_date",
    header: "ExpiryDate",
    type: "text",
    required: false,
    size: 140,
  },

  {
    accessorKey: "is_active",
    header: "IsActive",
    type: "text",
    required: false,
    size: 140,
    Cell: TableToggleCell,
  },
];
export default TableHeaders;
