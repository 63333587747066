import React from 'react'

function ImageContainer({url}) {
  return (
    <img
    alt="avatar"
    width={100}
    height={100}
    src={!url? '../../noimage.jpg' : `${url}`}
    loading="lazy"

    style={{ borderRadius: '2%', cursor: 'pointer' ,backgroundColor:"#555" }}
  />
  )
}

export default ImageContainer