import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, DiractionModeContext, useMode } from "./theme";
import Login from "./app/pages/Login";
import { RequireAuth, useAuthHeader, useIsAuthenticated } from "react-auth-kit";

import SupportChat from "./app/pages/chats/supportChat";

import { onMessageListener } from "./firebaseInit";
import axios from "axios";
import Notifications from "./app/components/Notifications/Notifications";
import ReactNotificationComponent from "./app/components/Notifications/ReactNotification";
import ProvidersPage from "./app/pages/providersPage";
import ProviderDepartmentPage from "./app/pages/providerDepartmentPage";
import CategorysPage from "./app/pages/categoriesPage";
import SectionsPage from "./app/pages/sectionsPage";
import VouchersPage from "./app/pages/vouchersPage";
import AdvertisingsPage from "./app/pages/advertisingPage";
import ClientsPage from "./app/pages/clientsPage";
import OrdersPage from "./app/pages/ordersPage";
import CouponsPage from "./app/pages/couponsPage";
import AccountingsPage from "./app/pages/accountingsPage";
import ProfilePage from "./app/pages/profile";
import AdminsPage from "./app/pages/adminsPage";
import NewDashboard from "./app/pages/dashboard";
import ReportsPage from "./app/pages/reportsPage";
import ConnectionsPage from "./app/pages/connectionsPage";
import TermsPage from "./app/pages/termsPage";
import CustomSlidePage from "./app/pages/customSlidePage";
import GiftsPage from "./app/pages/giftsPage";
import PointsPage from "./app/pages/pointPage";
import OrderGiftPage from "./app/pages/orderGiftPage"

function App() {
  const [theme, colorMode, directionMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const isAuthenticated = useIsAuthenticated();
  const authHeader = useAuthHeader();

  const setAuthHeader = (config) => {
    config.headers["Authorization"] = authHeader();
    return config;
  };

  axios.interceptors.request.use(
    (config) => setAuthHeader(config),
    (error) => Promise.reject(error)
  );

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  const HandleNotificationPermission = () => {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      console.log(Notification.permission);
    } else if (Notification.permission !== "granted") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        console.log(permission);
      });
    }
  };
  
  HandleNotificationPermission();
  return (
    <>
      <Notifications />

      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <DiractionModeContext.Provider value={directionMode}>
            <div className="app" style={{ direction: theme.direction }}>
              {!isAuthenticated() ? (
                <Login />
              ) : (
                <>
                  {isSidebar && <Sidebar isSidebar={isSidebar} />}
                  <main className="content">
                    <Topbar setIsSidebar={setIsSidebar} />
                    <Routes>
                      <Route path="/login" element={<Login />} />

                      <Route
                        path="/"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <NewDashboard />
                            <></>
                          </RequireAuth>
                        }
                      />

                      <Route
                        path="/categories"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <CategorysPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/sections"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <SectionsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/point"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <PointsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/advertisings"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <AdvertisingsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/providers"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <ProvidersPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/providerDepartment"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <ProviderDepartmentPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/vouchers"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <VouchersPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/clients"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <ClientsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/orders"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <OrdersPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/reports"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <ReportsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/copouns"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <CouponsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/customSlide"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <CustomSlidePage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/gifts"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <GiftsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/orderGift"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <OrderGiftPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/accounts"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <AccountingsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/profile"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <ProfilePage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/admins"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <AdminsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/connections"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <ConnectionsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/info"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <TermsPage />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path="/chats"
                        element={
                          <RequireAuth loginPath={"/login"}>
                            <SupportChat />
                          </RequireAuth>
                        }
                      />
                    </Routes>
                  </main>
                </>
              )}
            </div>
          </DiractionModeContext.Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>

      {show && (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      )}
    </>
  );
}

export default App;
