const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  addMode: false,
  editMode: false,
  viewMode: false,
  deleteMode: false,
  acceptMode: false,
  cancelMode: false,
  itemToAccept: null,
  itemToCancel: null,
  itemToEdit: null,
  itemToView: null,
  itemToDelete: null,
};

const OrderGiftSlice = createSlice({
  name: "orderGift",
  initialState,
  reducers: {
    setAddMode: (state, action) => {
      state.addMode = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
    setDeleteMode: (state, action) => {
      state.deleteMode = action.payload;
    },
    setAcceptMode: (state, action) => {
      state.acceptMode = action.payload;
    },
    setCancelMode: (state, action) => {
      state.cancelMode = action.payload;
    },
    setItemToEdit: (state, action) => {
      state.itemToEdit = action.payload;
    },
    setItemToView: (state, action) => {
      state.itemToView = action.payload;
    },
    setItemToDelete: (state, action) => {
      state.itemToDelete = action.payload;
    },
    setItemToAccept: (state, action) => {
      state.itemToAccept = action.payload;
    },
    setItemToCancel: (state, action) => {
      state.itemToCancel = action.payload;
    },
  },
});

export const {
  setAddMode,
  setEditMode,
  setViewMode,
  setDeleteMode,
  setItemToEdit,
  setItemToView,
  setItemToDelete,
  setItemToCancel,
  setItemToAccept,
  setAcceptMode,
  setCancelMode
} = OrderGiftSlice.actions;

export default OrderGiftSlice;
