const TableHeaders = [
  {
    accessorKey: "name",
    header: "Name",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "email",
    header: "Email",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "type",
    header: "Type",
    type: "text",
    required: false,
    size: 140,
  },
];
export default TableHeaders;
