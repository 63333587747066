import { Typography } from "@mui/material";
import { Box, Button, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import { setItemToPinView, setViewPin} from '../../data/slices/customSlideSlice';

const VouchersCount = ({ row }) => {
  return <Typography>{row.original.vouchers?.length}</Typography>;
};

const AdvertiseVoucher = ({ row }) => {
  const dispatch = useDispatch();

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Tooltip arrow placement="left" title={t("PinVouchers")}>
        <Button
          variant="contained"
          color="info"
          sx={{
            width: "80%",
          }}
          onClick={() => {
            dispatch(setItemToPinView(row.original));
            dispatch(setViewPin(true));
          }}
        >
          {t("PinVouchers")}
        </Button>
      </Tooltip>
    </Box>
  );
};

const TableHeaders = [
  {
    accessorKey: "name",
    header: "Name",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "vouchers",
    header: "Vouchers",
    type: "text",
    required: false,
    size: 140,
    Cell: VouchersCount,
  },
  {
    accessorKey: "advertise",
    header: "PinVouchers",
    type: "text",
    required: false,
    size: 140,
    Cell: AdvertiseVoucher,
  },
];
export default TableHeaders;
