import {  ThumbUpAlt, Cancel, RemoveRedEye } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { t } from 'i18next';
import React from 'react'
import { useDispatch } from 'react-redux';
import { setItemToAccept, setAcceptMode, setItemToCancel, setCancelMode, setItemToView,setViewMode } from '../../data/slices/orderGiftSlice';

function TableActions(row) {
    const dispatch = useDispatch();
    return (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
          <Tooltip arrow placement="left" title={t("AcceptOrderGift")}>
          <IconButton
            onClick={() => {
              dispatch(setItemToAccept(row.original));
              dispatch(setAcceptMode(true));
            }}
          >
            <ThumbUpAlt  color="success" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow placement="left" title={t("CancelOrderGift")}>
          <IconButton
            onClick={() => {
              dispatch(setItemToCancel(row.original));
              dispatch(setCancelMode(true));
            }}
          >
            <Cancel  color="error" />
          </IconButton>
        </Tooltip>

        <Tooltip arrow placement="left" title={t("View")}>
              <IconButton  onClick={() => {
                dispatch(setItemToView(row.original))
                dispatch(setViewMode(true))
                
              }}>
                <RemoveRedEye color='warning'/>
              </IconButton>
        </Tooltip>
         
          </Box>
        </>
      );
}

export default TableActions