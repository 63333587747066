import React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box, Chip, Grid, Stack, useTheme } from '@mui/material';
import ImagePreview from './ImagePreview';
import flattenObject from '../../utils/flattenObject';
import { red } from '@mui/material/colors';
import AweomeImage from './AwesomImage';
import { Check, Close, FileDownload, FileDownloadDoneTwoTone } from '@mui/icons-material';
import html2pdf from 'html2pdf.js';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ data, open, columns, onClose }) {
  const theme = useTheme();
  console.log(data)
  if (data == null) {
    return <></>;
  }
 const colors =['#795548','#673ab7','#00b0ff','#009688','#ff1744','#ff5252','#ef5350']
  data = flattenObject(data);
  const exportToPDF = () => {
    const input = document.getElementById('full-screen-dialog'); // Make sure to give this ID to the root element of the component's JSX
    const pdfOptions = {
      margin: [0, 0, 10, 0], // [left, top, right, bottom]
      filename: 'offers-and-awareds.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };
  
    html2pdf().from(input).set(pdfOptions).save();
  };
  return (
    <Box >
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        dir={theme.direction}
   
      >
      <AppBar sx={{ position: 'relative', backgroundColor: '#212121' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>

          <div>
            <IconButton edge="start" color="inherit" onClick={exportToPDF} aria-label="another action">
              <FileDownload />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

        <Grid id="full-screen-dialog" dir={theme.direction} sx={{paddingTop:"2rem" ,width:"100%"}} container spacing={4}>
        {columns.map((column, index) => {
          let content;

          if (column.type === 'image') {
            content = <ImagePreview data={data} accessorKey={column.accessorKey} />;
          } else if (column.type === 'array') {
            content = (
              <Stack direction="row" spacing={1}>
                {data[column.accessorKey].map((item, i) => (
                  <Chip
                    key={i}
                    label={column.list?.find(option => option.value === item)?.label}
                    style={{ backgroundColor: colors[Math.floor(Math.random() * 7)], color: 'white', marginRight: '1.5rem' }}
                  />
                ))}
              </Stack>
            );
          } 
          else if (column.type === 'select-list') {
            content = (
              <Stack direction="row" spacing={1}>
                {data[column.accessorKey].map((item, i) => (
                  <Chip
                    key={i}
                    label={item.label}
                    style={{ backgroundColor: colors[Math.floor(Math.random() * 7)], color: 'white', marginRight: '1.5rem' }}
                  />
                ))}
              </Stack>
            );
          }
          
          
          else if (column.type === 'gallery') {
            content = (
              <Stack direction="row" spacing={1}>
                {data[column.accessorKey].map((item, i) => (
                  <AweomeImage url={item.image_url}/>
                ))}
              </Stack>
            );
          }
          else if (column.type === 'bool') {
            content = data[column.accessorKey] ?(
              <Check color="success" />
            ): (
              <Close color="error" />
            );
          }

          else {
            content = <Typography>{data[column.accessorKey] || ''}</Typography>;
          }

          return (
            <Grid
              key={column.accessorKey}
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '2rem',
                padding: '4rem',
                borderRadius: '12px',
                color: '#333',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  marginBottom: '1rem',
                  color: '#444',
                }}
              >
                {column.header || ''}
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>{content}</div>
            </Grid>
          );
        })}

        </Grid>
      </Dialog>
    </Box>
  );
}
