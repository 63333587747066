import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItemText,
  ListItem,
  useTheme,
  Button,
} from "@mui/material";
import { t } from "i18next";
import { tokens } from "../../../theme";
import html2pdf from "html2pdf.js";

const OrderInvoice = ({ order }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (!order) {
    return <Typography>{t("NoDataAvailable")}</Typography>;
  }
  const exportToPDF = () => {
    const input = document.getElementById("pdf-content"); // Make sure to give this ID to the root element of the component's JSX
    const pdfOptions = {
      margin: [0, 0, 10, 0], // [left, top, right, bottom]
      filename: "order_invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(input).set(pdfOptions).save();
  };

  return (
    <>
      <Button onClick={exportToPDF} variant="outlined" color="primary">
        {t("ExportToPDF")}
      </Button>
      <Grid
        id="pdf-content"
        item
        xs={12}
        sm={6}
        key={order.order_number}
        dir={theme.direction}
        color={colors.primary[600]}
      >
        <Card
          sx={{
            marginBottom: theme.spacing(4),
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            borderRadius: theme.spacing(2),
            padding: theme.spacing(2),
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              sx={{
                marginBottom: theme.spacing(2),
                fontWeight: "bold",
                borderBottom: `2px solid ${colors.greenAccent[500]}`,
              }}
            >
              {t("OrderNumber")}: {order.order_number}
            </Typography>
            <Typography color="textSecondary">
              {t("CreatedAt")}: {order.date}
            </Typography>
            <Typography color="textSecondary">
              {t("TotalCost")}: {order.total}
            </Typography>
            <Typography color="textSecondary">
              {t("CompanyShare")}: {order.company_share}
            </Typography>
            <Typography color="textSecondary">
              {t("Status")}: {t(order.status)}
            </Typography>
            <Typography color="textSecondary">
              {t("Location")}: {t(order.location)}
            </Typography>

            {/* Provider */}
            <Typography
              variant="h6"
              sx={{
                marginBottom: theme.spacing(2),
                fontWeight: "bold",
                borderBottom: `2px solid ${colors.greenAccent[500]}`,
              }}
            >
              {t("Provider")}
            </Typography>
            <List disablePadding dir={theme.direction}>
              <ListItem
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  textAlign: theme.direction === "rtl" ? "right" : "left",
                }}
              >
                <ListItemText
                  primary={t("Name")}
                  secondary={order.provider.name}
                />
                <ListItemText
                  primary={t("Phone")}
                  secondary={order.provider.phone}
                />
                <ListItemText
                  primary={t("CompanyName")}
                  secondary={order.provider.company_name}
                />
              </ListItem>
            </List>

            {/* Client */}
            <Typography
              variant="h6"
              sx={{
                marginBottom: theme.spacing(2),
                fontWeight: "bold",
                borderBottom: `2px solid ${colors.greenAccent[500]}`,
              }}
            >
              {t("Client")}
            </Typography>
            <List disablePadding>
              <ListItem
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  borderBottom: `1px solid ${theme.palette.grey[300]}`,
                  textAlign: theme.direction === "rtl" ? "right" : "left",
                }}
              >
                <ListItemText
                  primary={t("Name")}
                  secondary={order.client?.name}
                />
                <ListItemText
                  primary={t("Phone")}
                  secondary={order.client?.phone}
                />
                <ListItemText
                  primary={t("Address")}
                  secondary={order.client?.address}
                />
              </ListItem>
              {/* Update other client fields */}
            </List>

            {/* Products */}
            <Typography
              variant="h6"
              sx={{
                marginBottom: theme.spacing(2),
                fontWeight: "bold",
                borderBottom: `2px solid ${colors.greenAccent[500]}`,
              }}
            >
              {t("Products")}
            </Typography>
            <List disablePadding>
              {order.invoices.map((invoice, index) => (
                <ListItem
                  key={index}
                  sx={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                    textAlign: theme.direction === "rtl" ? "right" : "left",
                  }}
                >
                  <ListItemText
                    primary={invoice.name}
                    secondary={`${t("Quantity")}: ${invoice.quantity} | ${t(
                      "Price"
                    )}: ${invoice.price_after} ${invoice.currency} `}
                  />
                </ListItem>
              ))}
            </List>

            {/* Display other fields */}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default OrderInvoice;
