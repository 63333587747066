const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  addMode: false,
  editMode: false,
  viewMode: false,
  deleteMode: false,
  notifyMode: false,

  itemToEdit: null,
  itemToView: null,
  itemToDelete: null,
  itemToNotify: null,
};

const ClientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setAddMode: (state, action) => {
      state.addMode = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    setViewMode: (state, action) => {
      state.viewMode = action.payload;
    },
    setDeleteMode: (state, action) => {
      state.deleteMode = action.payload;
    },
    setNotifyMode: (state, action) => {
      state.notifyMode = action.payload;
    },
    setItemToEdit: (state, action) => {
      state.itemToEdit = action.payload;
    },
    setItemToView: (state, action) => {
      state.itemToView = action.payload;
    },
    setItemToDelete: (state, action) => {
      state.itemToDelete = action.payload;
    },
    setItemToNotify: (state, action) => {
      state.itemToNotify = action.payload;
    },
  },
});

export const {
  setAddMode,
  setEditMode,
  setViewMode,
  setDeleteMode,
  setNotifyMode,

  setItemToEdit,
  setItemToView,
  setItemToDelete,
  setItemToNotify,
} = ClientSlice.actions;

export default ClientSlice;
