import { useMemo } from "react";
import AwesomeTable from "../../components/Awesome/AweomeTable";

import { t } from "i18next";
import TableHeaders from "./tableHeaders";
import Loader from "../../components/Common/Loader";
import TableActions from "./tableActions";
import {
  setAddMode,
  setEditMode,
  setItemToEdit,
  setDeleteMode,
  setItemToDelete,
  setItemToNotify,
  setItemToView,
  setNotifyMode,
  setViewMode,
} from "../../data/slices/clientSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllClientsQuery,
  useDeleteClientMutation,
} from "../../data/api/client";
import FullScreenDialog from "../../components/Awesome/FullScreenDialog";
import { useCreateNotificationMutation } from "../../data/api/notification";
import mapToFormData from "../../utils/mapToFormData";
import AwesomeDialog from "../../components/Awesome/AwesomeDialog";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";

function ClientsPage() {
  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError, error, ...props } =
    useGetAllClientsQuery();
  const {
    itemToView,
    viewMode,
    addMode,
    deleteMode,
    editMode,
    itemToEdit,
    itemToDelete,
    notifyMode,
    itemToNotify,
  } = useSelector((state) => state.client);
  const [notifyClient, notifyClientResult] = useCreateNotificationMutation();

  const [deleteClient, deleteClientResult] = useDeleteClientMutation();

  const columns = useMemo(
    () =>
      TableHeaders.map((header) => ({
        ...header,
        header: t(header.header),
      })),
    []
  );

  if (deleteClientResult.isSuccess) {
    deleteClientResult.reset();
    props.refetch();
  }
  const onNotify = (body) => {
    body.user_id = itemToNotify.id;
    body.type = "other";
    body = mapToFormData(body);

    notifyClient(body);
  };
  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }

  return (
    <>
      {data && (
        <AwesomeTable
          columns={columns}
          data={data.data}
          showActions={false}
          RowActions={TableActions}
          showTopActions={false}
        />
      )}

      <FullScreenDialog
        data={itemToView}
        columns={[
          { accessorKey: "name", header: t("Name") },
          { accessorKey: "email", header: t("Email") },
          { accessorKey: "phone", header: t("Phone") },
          { accessorKey: "points", header: t("Points")},
          { accessorKey: "government", header: t("Government") },
          { accessorKey: "address", header: t("Address") },
          { accessorKey: "area", header: t("Area") },
          { accessorKey: "birthdate", header: t("Birthdate") },
          { accessorKey: "is_blocked", type: "bool", header: t("IsBlocked") },
          {
            accessorKey: "profile_image_url",
            type: "image",
            header: t("Image"),
          },
        ]}
        open={viewMode}
        onClose={() => {
          dispatch(setItemToView(null));
          dispatch(setViewMode(false));
        }}
      />

      <AwesomeDialog
        columns={[
          { accessorKey: "body", required: true, header: t("Body") },
          {
            accessorKey: "image",
            type: "image",
            required: false,
            header: t("Image"),
          },
        ]}
        open={notifyMode}
        onClose={() => {
          dispatch(setNotifyMode(false));
          dispatch(setItemToNotify(null));
        }}
        onSubmit={onNotify}
      />

      <ConfirmationDialog
        open={deleteMode}
        onClose={() => {
          dispatch(setItemToDelete(null));
          dispatch(setDeleteMode(false));
        }}
        onConfirm={() => {
          deleteClient({ id: itemToDelete.id });
        }}
        message={t("DeleteConfirmation")}
      />
    </>
  );
}

export default ClientsPage;
