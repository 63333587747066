import { Delete, Edit, NotificationAdd, RemoveRedEye, PushPin, HighlightOffTwoTone, Add} from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { blue, pink, grey, red } from "@mui/material/colors";
import { t } from 'i18next';
import React from 'react'
import { useDispatch } from 'react-redux';
import { setDeleteMode, setItemToAdd, setAddProviderDepartment, setEditMode, setItemToDelete, setItemToEdit, setItemToNotify, setItemToView, setNotifyMode, setViewMode, setItemToPin, setPinVoucher, setRemovePinVoucher, setItemToRemovePin, setItemToProviderDepartment } from '../../data/slices/voucherSlice';
import { useGetProviderDepartmentByProviderSelectListMutation } from "../../data/api/providerDepartment.js"
function TableActions({ row, filter }) {
    const dispatch = useDispatch();
    const [getProviderDepartment, getProviderDepartmentResult] =useGetProviderDepartmentByProviderSelectListMutation();

    const handleAddDepartmentClick = async () => {
      try {
        const response = await getProviderDepartment({ provider_id: row.original.provider_id });
        const providerDepartmentData = response.data?.data || [];
        dispatch(setItemToProviderDepartment(providerDepartmentData));
        dispatch(setItemToAdd(row.original));
        dispatch(setAddProviderDepartment(true));
      } catch (error) {
        // Handle error
      }
    };
    return (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
            <Tooltip arrow placement="left" title={t("View")}>
              <IconButton  onClick={() => {
                dispatch(setItemToView(row.original))
                dispatch(setViewMode(true))
                
              }}>
                <RemoveRedEye color='warning'/>
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="left" title=  {t("Edit")}>
                <IconButton  onClick={() => {
                dispatch(setItemToEdit(row.original))
                dispatch(setEditMode(true))

                }}>
                    <Edit color='success'/>
                </IconButton>
            </Tooltip>
            <Tooltip arrow placement="left" title=  {t("Delete")}>
                <IconButton  onClick={() => {
                dispatch(setItemToDelete(row.original))
                dispatch(setDeleteMode(true))
                
                }}>
                    <Delete color='error'/>
                </IconButton>
            </Tooltip>
            <Tooltip arrow placement="left" title={t("Notify")}>
            <IconButton
              sx={{
                color: blue[800],
              }}
              onClick={() => {
                dispatch(setItemToNotify(row.original));
                dispatch(setNotifyMode(true));
              }}
            >
              <NotificationAdd />
            </IconButton>
          </Tooltip>
          {filter === 'all' && (
            <Tooltip arrow placement="left" title={t("Pin")}>
              <IconButton
              sx={{
                color: row.original.is_pinned ? blue[400] : grey[400],
              }}
                onClick={() => {
                  dispatch(setItemToPin(row.original));
                  dispatch(setPinVoucher(true));
                }}>
                <PushPin fontSize="medium"/>
              </IconButton>
            </Tooltip>
          )}

          {(filter === 'new' || filter === 'hot' || filter === 'most_sold') && (
            <Tooltip arrow placement="left" title={t("RemovePin")}>
              <IconButton
              sx={{
                color: red[600],
              }}
                onClick={() => {
                  dispatch(setItemToRemovePin(row.original));
                  dispatch(setRemovePinVoucher(true));
                }}>
                <HighlightOffTwoTone fontSize="medium"/>
              </IconButton>
            </Tooltip>
          )}

            <Tooltip arrow placement="left" title=  {t("AddVoucherToProviderDepartment")}>
                <IconButton  onClick={handleAddDepartmentClick}>
                    <Add color='info'/>
                </IconButton>
            </Tooltip>
         
          </Box>
        </>
      );
}

export default TableActions