import React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { Box } from "@mui/material";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const DirectionProvider = ({ children }) => {
  const direction = localStorage.getItem("direction") ?? "rtl";
  return (
    <CacheProvider value={cacheRtl}>
      <Box sx={{ width: "100%" }} dir={direction}>
        {children}
      </Box>
    </CacheProvider>
  );
};

export default DirectionProvider;
