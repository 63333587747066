import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosBaseQuery = async (args, api, extraOptions) => {
  const { url, method = "GET", body, headers } = args;
  const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;
  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: body,
      headers,
    });

    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

const notificationAPI = createApi({
  refetchOnMountOrArgChange: 10,
  reducerPath: "notificationApi",
  tagTypes: ["Notification"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getAllNotifications: builder.query({
      query: () => ({
        url: `/notification`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Notification"],
    }),
    createNotification: builder.mutation({
      query: (body) => ({
        url: `/notification`,
        method: "POST",
        body,
      }),
      headers: {
        "Content-Type": "multipart/form-data",
      },
      providesTags: ["Notification"],
    }),
    makeNotifiactionAsRead: builder.mutation({
      query: ({ id }) => ({
        url: `/notifiaction/make-as-read/${id}`,
        method: "PUT",
      }),
      headers: {
        "Content-Type": "multipart/form-data",
      },
      providesTags: ["Notification"],
    }),
    getUnreadNotifiactionCount: builder.query({
      query: () => ({
        url: `/notification/unread-count`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["Notification"],
    }),
  }),
});

export const {
  useGetAllNotificationsQuery,
  useCreateNotificationMutation,
  useMakeNotificationAsReadMutation,
  useGetUnreadNotifiactionCountQuery,
  useToggleActiveNotificationMutation,
} = notificationAPI;
export default notificationAPI;
