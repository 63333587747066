const getAreaName = async (lat, lng) => {
  const languageCode = "ar"; // Arabic language code

  const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&accept-language=${languageCode}`;

  try {
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      return data.address;
    } else {
      console.error("Error retrieving area name:", response.statusText);
      return "Area not found";
    }
  } catch (error) {
    console.error("Error:", error);
    return "Area not found";
  }
};
export default getAreaName;
