import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import AwesomMap from './AwesomMap';

function AwesomeMapContainer({open,handleMapState,callback}) {
    
    const [selectedPosition, setSelectedPosition] = useState("");

  
    const handleSelectedPosition = (val) => {
        setSelectedPosition(val);
    };
    const handleClose = () => {
        handleMapState(false);
    };
  

    return (
      <div>

        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>{selectedPosition.name}</DialogTitle>
          <DialogContent>
                <AwesomMap callback={handleSelectedPosition}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              إلغاء
            </Button>
            <Button
              onClick={() => {
                callback(selectedPosition);
                handleClose();
              }}
              color="primary"
            >
              إختيار
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}

export default AwesomeMapContainer