import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";

const axiosBaseQuery = async (args, api, extraOptions) => {
  const { url, method = "GET", body, headers } = args;
  const apiUrl = `${process.env.REACT_APP_API_URL}${url}`;
  try {
    const response = await axios({
      method,
      url: apiUrl,
      data: body,
      headers,
    });

    return { data: response.data };
  } catch (error) {
    return { error };
  }
};

const customSlideAPI = createApi({
  refetchOnMountOrArgChange: 10,
  reducerPath: "customSlideApi",
  tagTypes: ["CustomSlide"],
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getAllCustomSlides: builder.query({
      query: () => ({
        url: `/custom-slides`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["CustomSlide"],
    }),
    getCustomSlidesList: builder.query({
      query: () => ({
        url: `/custom-slides/list`,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      providesTags: ["CustomSlide"],
    }),

    addCustomSlide: builder.mutation({
      query: (body) => ({
        url: `/custom-slides`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["CustomSlide"],
    }),
    appendCustomSlide: builder.mutation({
      query: ({ body, id }) => ({
        url: `/custom-slides/append/${id}`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["CustomSlide"],
    }),
    editCustomSlide: builder.mutation({
      query: ({ body, id }) => ({
        url: `/custom-slides/${id}`,
        method: "PUT",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["CustomSlide"],
    }),
    deleteCustomSlide: builder.mutation({
      query: ({ id }) => ({
        url: `/custom-slides/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["CustomSlide"],
    }),
    pinVoucherToCustomSlide: builder.mutation({
      query: (body) => ({
        url: `/custom-slides/pin-voucher`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: ["CustomSlide"],
    }),
    removePinnedVoucher: builder.mutation({
      query: (body) => ({
        url: `/custom-slides/remove-pin`,
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      providesTags: ["CustomSlide"],
  }),
  }),
});

export const {
  useGetAllCustomSlidesQuery,
  useGetCustomSlidesListQuery,
  useAddCustomSlideMutation,
  useAppendCustomSlideMutation,
  useEditCustomSlideMutation,
  useDeleteCustomSlideMutation,
  useRemovePinnedVoucherMutation,
  usePinVoucherToCustomSlideMutation
} = customSlideAPI;
export default customSlideAPI;
