import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCrsmP2Fqk4tqQB5zP_euuhdvjx0QRX8B0",
  authDomain: "offers-and-awards.firebaseapp.com",
  projectId: "offers-and-awards",
  storageBucket: "offers-and-awards.appspot.com",
  messagingSenderId: "650620862352",
  appId: "1:650620862352:web:c03d080587f720e249bd15",
  measurementId: "G-PTWRBTM971"
};

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;
const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app, { vapidKey: 'BHzptN2QLp057Nfdv3QN2PnjLeU6OEEYmstFxtvBiNkDv1CttzxG8JApqRWynBjhBBREe3_TiDUOMcInVK5VOYQ'});

export const fetchToken = async () => {
  try {
    const fcmToken = await getToken(messaging, { vapidKey: 'BHzptN2QLp057Nfdv3QN2PnjLeU6OEEYmstFxtvBiNkDv1CttzxG8JApqRWynBjhBBREe3_TiDUOMcInVK5VOYQ' });
    if (fcmToken) {
      return fcmToken
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      return null;
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
    return null;
  }
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload.notification);
    });
  });