import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Paper, Typography, Box } from '@mui/material';
import { t } from 'i18next';
import EditProfile from './EditProfile';
import Loader from '../../components/Common/Loader'
import { useGetProfileInfoQuery } from '../../data/api/admin';

function ProfilePage() {
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '16px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
        },
        text: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
        button: {
            marginTop: '12px',
            alignSelf: 'flex-start',
        },
    };

    
    const [isEditing, setIsEditing] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');


    const dispatch = useDispatch();

    const { data, isLoading, isFetching, isError, error } = useGetProfileInfoQuery();



    const handleEditClick = () => {
        setIsEditing(true);
        setNewEmail(data?.data?.email);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setNewEmail(data?.data?.email);
        setOldPassword('');
        setNewPassword('');
    };

    const handleSaveChanges = () => {
        setIsEditing(false);
        setOldPassword('');
        setNewPassword('');
    };

    if (isLoading || isFetching || isError) {
        return <Loader isError={isError} error={error?.message} />;
      }
    
    return (
    <Paper
        sx={{
            padding: 3,
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            minHeight:"75%",
            alignItems: 'center',
        }}
        elevation={3}
    >
    
        <Box sx={{width:"60%"}}>
            <Typography sx={{mb:3}} variant="h5">{t("Profile")}</Typography>
            {isEditing ? (
                <EditProfile admin={data?.data} handleCancelEdit={handleCancelEdit} handleSaveChanges={handleSaveChanges}/>
            ) : (
                <div sx={styles.container}>
                    <Typography sx={styles.text}>
                        {t("Email")}: {data?.data?.email}
                    </Typography>
                    <Typography sx={styles.text}>
                        {t("Name")}: {data?.data?.name}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEditClick}
                        sx={styles.button}
                    >
                        {t("Edit")}
                    </Button>
                </div>
            )}
        </Box>
    </Paper>
    );
}

export default ProfilePage;
