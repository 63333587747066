import { Delete, Edit, RemoveRedEye, PushPin,  HighlightOffTwoTone } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { blue, red} from "@mui/material/colors";
import { t } from 'i18next';
import React from 'react'
import { useDispatch } from 'react-redux';
import { setDeleteMode, setEditMode, setItemToDelete, setItemToEdit, setItemToView, setViewMode, setPinVoucher, setItemToPin,  setRemovePinVoucher, setItemToRemovePin } from '../../data/slices/customSlideSlice';

function TableActions(row) {
    const dispatch = useDispatch();
    return (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
          <Tooltip arrow placement="left" title={t("View")}>
              <IconButton  onClick={() => {
                dispatch(setItemToView(row.original))
                dispatch(setViewMode(true))
                
              }}>
                <RemoveRedEye color='warning'/>
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="left" title=  {t("Edit")}>
                <IconButton  onClick={() => {
                  
                dispatch(setItemToEdit(row.original))
                dispatch(setEditMode(true))

                }}>
                    <Edit color='success'/>
                </IconButton>
            </Tooltip>
            <Tooltip arrow placement="left" title=  {t("Delete")}>
                <IconButton  onClick={() => {
                dispatch(setItemToDelete(row.original))
                dispatch(setDeleteMode(true))
                
                }}>
                    <Delete color='error'/>
                </IconButton>
            </Tooltip>

            <Tooltip arrow placement="left" title={t("Pin")}>
              <IconButton
              sx={{
                color:  blue[400],
              }}
                onClick={() => {
                  dispatch(setItemToPin(row.original));
                  dispatch(setPinVoucher(true));
                }}>
                <PushPin fontSize="medium"/>
              </IconButton>
            </Tooltip>
          
            <Tooltip arrow placement="left" title={t("RemovePin")}>
              <IconButton
              sx={{
                color: red[600],
              }}
                onClick={() => {
                  dispatch(setItemToRemovePin(row.original));
                  dispatch(setRemovePinVoucher(true));
                }}>
                <HighlightOffTwoTone fontSize="medium"/>
              </IconButton>
            </Tooltip>
          </Box>
        </>
      );
}

export default TableActions