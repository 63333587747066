import { Box, IconButton, useTheme, Menu, MenuItem, Badge, Typography , Grid, Card, CardContent } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { ColorModeContext, DiractionModeContext, tokens} from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AbcIcon from '@mui/icons-material/Abc';
import { Logout, NotificationAdd } from "@mui/icons-material";
import { useSignOut } from 'react-auth-kit'
import { useDispatch, useSelector } from "react-redux";
import { setNotifyMode } from "../../app/data/slices/voucherSlice";
import { setAddMode } from "../../app/data/slices/notificationSlice";
import AwesomeDialog from "../../app/components/Awesome/AwesomeDialog";
import mapToFormData from "../../app/utils/mapToFormData";
import { useCreateNotificationMutation } from "../../app/data/api/notification";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useGetGovernmentsQuery } from "../../app/data/api/client";
import { useGetUnreadNotifiactionCountQuery, useGetAllNotificationsQuery } from "../../app/data/api/notification";
import Loader from '../../app/components/Common/Loader';

const Topbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const dirctionMode = useContext(DiractionModeContext);
  const signOut = useSignOut();
  var {data,isLoading} = useGetGovernmentsQuery();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [governments,setGovernments] = useState([]);
  const addNotification =()=>{
    dispatch(setAddMode(true));
  }
  const {

    addMode,
   } = useSelector(state=> state.notification);
   const [createNotification, createNotificationResult] = useCreateNotificationMutation();
   const { data: notifications, refetch } = useGetAllNotificationsQuery();
   const { data: count, refetch: refetchCount } = useGetUnreadNotifiactionCountQuery();
   const onNotify = (body) => {
  
    body.data = JSON.stringify({})
    body.type = "other"
    if(typeof body.center === "object"){

      body.center= `${body.center.latLng.lat}, ${body.center.latLng.lng}`;
    }
    body = mapToFormData(body);

    createNotification(body);

  };
  useEffect(()=>{
    if(data && !isLoading){

      setGovernments(data.map(e=>{return {label:e,value:e}}))
    }
  },[isLoading,data]);





  const [viewMode, setViewMode] = useState(false);
  const [itemToView, setItemToView ] = useState();

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setItemToView(null);
    setViewMode(false);
  }
  const handleNotificationItemClick = async (notification) => {
    setItemToView(notification);
    setViewMode(true);
    handleNotificationClose();
    const api = process.env.REACT_APP_API_URL;
    await axios.put(`${api}/notification/make-as-read/${notification.id}`);
};
const handleNotificationClick = async (event) => {
  setAnchorEl(event.currentTarget);
  setLoading(true);

  // Refresh the data
  await refetch();

  // Refresh the count
  await refetchCount();

  // Hide loading state
  setLoading(false)
};

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >

        <IconButton onClick={handleNotificationClick}>
          {count !== undefined && (
            <Badge badgeContent={count} color="secondary">
              <NotificationsOutlinedIcon />
            </Badge>
          )}
        </IconButton>

        <IconButton onClick={addNotification}>
          <NotificationAdd />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleNotificationClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
     {loading ? (
          <MenuItem disabled> <Loader/></MenuItem>
        ) : notifications  && notifications.data.length > 0 ? (
          notifications.data.map((notification) => (
          <MenuItem
              key={notification.id}
              onClick={() => handleNotificationItemClick(notification)}
              sx={{
                backgroundColor: notification.is_read ? "transparent" : colors.grey[900],
                padding: "8px 16px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                marginBottom: theme.spacing(0.2),
              }}
            >
            <Box display="flex" alignItems="center">
              <Box>
                <Typography variant="body1" fontWeight="bold" color={notification.is_read ? "textSecondary" : "textPrimary"}>
                  {notification.body}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {notification.date}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No notifications</MenuItem>
        )}
      </Menu>
      <Dialog open={viewMode} dir={theme.direction} >
        <DialogTitle textAlign="center">{t("NotificationDetails")}</DialogTitle>
        <DialogContent>
        {itemToView && itemToView.data && (
      <Grid
        id="pdf-content"
        item
        xs={12}
        sm={6}
        dir={theme.direction}
        color={colors.primary[600]}
      >
        <Card
          sx={{
            marginBottom: theme.spacing(4),
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            borderRadius: theme.spacing(2),
            padding: theme.spacing(2),
          }}
        >
            <CardContent>
            <Typography
              variant="h5"
              sx={{
                marginBottom: theme.spacing(2),
                fontWeight: "bold",
                borderBottom: `2px solid ${colors.greenAccent[500]}`,
              }}
            >
              {t("OrderNumber")}: {itemToView.data.order_number}
              </Typography>
            <Typography color="textSecondary">
            {t("Provider")}: {itemToView.data.provider_name}
            </Typography>
            <Typography color="textSecondary">
            {t("Client")}: {itemToView.data.client_name}
            </Typography>
            </CardContent>
        </Card>
      </Grid>
      
      )}

      {itemToView && !itemToView.data &&(
        <Typography variant="body1" textAlign="center">
         {t("YouHaveNewMessageInChat")}
        </Typography>
      )}
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
      <Button color="inherit" onClick={handleDialogClose}>
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>

      {/* ICONS */}
      <Box display="flex">
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
        {/* <IconButton onClick={dirctionMode.toggleDirection}>
          <AbcIcon />
        </IconButton> */}
         <IconButton onClick={()=> {navigate('/profile')}} >
          <PersonOutlinedIcon />
        </IconButton>
        <IconButton onClick={() => signOut()}>
          <Logout />
        </IconButton>
        
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton>
          <PersonOutlinedIcon />
        </IconButton> */}
      </Box>
          <AwesomeDialog
      
      columns={[  
        { accessorKey: 'body',required:true, header: t('Body') },
        { accessorKey: 'government',required:false,type:"select",header: t('Government'), list: governments},
        { accessorKey: 'center',required:false,type:"place",header: t('Center')},
        { accessorKey: 'radius',required:false,type:"text",header: t('Radius')},
        { accessorKey: 'image',required:false, type:'image', header: t('Image') },
      ]}
      open={addMode}
      onClose={() => {
      dispatch(setAddMode(false))

      }}
      onSubmit={onNotify}
    />
    </Box>
  );
};

export default Topbar;
