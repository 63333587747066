import { useTheme } from "@emotion/react";
import { FileUploadOutlined } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { t } from "i18next";
import React, { useState, useEffect } from "react";
import DirectionProvider from "../../providers/DirectionProvider";
import flattenObject from "../../utils/flattenObject";
import { DesktopDatePicker, LocalizationProvider,TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ImageGrid from "./ImageGrid";
import { pink } from "@mui/material/colors";
import { object } from "yup";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import AwesomeMapContainer from "../Map/AwesomeMapContainer";
function AwesomeDialog({
  data,
  title,
  open,
  columns,
  onClose,
  onSubmit,
  ...props
}) {
  const [values, setValues] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [mapOpen, setMapOpen] = useState(false);
  useEffect(
    () => {
      console.log(data);
      if (data) {
        setValues(flattenObject(data, "", "_"));
      } else {
        setValues({});
      }
    },
    [data],
    () => {
      setValues({});
    }
  );

  const handleMapState = (newState) => {
    setMapOpen(newState);
  };

  const handleSubmit = () => {
    const errors = validateFields();
    if (Object.keys(errors).length === 0) {
      const copy = removeImagesString();
      onSubmit(copy);
      onClose();
      setValues({});
      setValidationErrors({});
    } else {
      setValidationErrors(errors);
      console.log(errors);
    }
  };
  const removeImagesString = () => {
    const copy = values;
    columns.forEach((element) => {
      if (element.type === "image") {
        if (!(copy[element.accessorKey] instanceof File)) {
          delete copy[element.accessorKey];
        }
      }
    });
    return copy;
  };

  const validateFields = () => {
    const errors = {};
    columns.forEach((column) => {
      if (column.required) {
        if (
          !values[column.accessorKey] &&
          values[column.accessorKey] !== false
        ) {
          errors[column.accessorKey] = t("ThisFieldIsRequired");
        }
      }
    });
    return errors;
  };

  const handleChange = (e) => {
    console.log(values);
    setValues({ ...values, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: "" });
    console.log(values);
  };
  const renderImagePreview = (image) => {
    if (image instanceof File) {
      return (
        <img
          alt="avatar"
          width={100}
          height={100}
          src={URL.createObjectURL(image)}
          loading="lazy"
          style={{ borderRadius: "2%" }}
        />
      );
    } else if (typeof image === "string") {
      return (
        <img
          alt="avatar"
          width={100}
          height={100}
          src={`${image}`}
          loading="lazy"
          style={{ borderRadius: "2%" }}
        />
      );
    }
    return null;
  };
  const theme = useTheme();

  return (
    <Dialog open={open} maxWidth="lg">
      <DialogTitle textAlign="center">{title}</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              marginTop: "10px",
              width: "100%",
              minWidth: { xs: "400px", sm: "560px", md: "600px" },
              gap: "1.5rem",
            }}
          >
            {columns.map((column, i) => {
              if (column.type === "image") {
                return (
                  <Box key={column.accessorKey}>
                    <DirectionProvider>
                      <TextField
                        variant="standard"
                        type="text"
                        sx={{ margin: "auto", width: "100%" }}
                        label={column.header}
                        dir={theme.direction}
                        value={values?.[column.accessorKey]?.name || ""}
                        InputProps={{
                          endAdornment: (
                            <IconButton component="label">
                              <FileUploadOutlined />
                              <input
                                style={{ display: "none" }}
                                type="file"
                                hidden
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  console.log(typeof file);
                                  setValues({
                                    ...values,
                                    [e.target.name]: file,
                                  });
                                }}
                                name={column.accessorKey}
                              />
                            </IconButton>
                          ),
                        }}
                        required={column.required}
                        error={!!validationErrors[column.accessorKey]}
                        helperText={validationErrors[column.accessorKey]}
                      />
                    </DirectionProvider>
                    {renderImagePreview(values?.[column.accessorKey])}
                  </Box>
                );
              } else if (column.type === "gallery") {
                return (
                  <ImageGrid
                    key={column.accessorKey}
                    data={data?.[column.accessorKey]}
                    handleChanges={(files) => {
                      setValues({ ...values, [column.accessorKey]: files });
                    }}
                  />
                );
              } else if (column.type === "select") {
                return (
                  <Box key={column.accessorKey}>
                    <DirectionProvider>
                      <TextField
                        select
                        variant="standard"
                        dir={theme.direction}
                        sx={{ margin: "auto", width: "100%" }}
                        key={column.accessorKey}
                        label={column.header}
                        name={column.accessorKey}
                        value={
                          column.list?.find(
                            (option) =>
                              option.value === values[column.accessorKey]
                          )?.value || ""
                        }
                        required={column.required}
                        error={!!validationErrors[column.accessorKey]}
                        helperText={validationErrors[column.accessorKey]}
                        onChange={handleChange}
                      >
                        {column.list?.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            dir={theme.direction}
                          >
                            {t(option.label)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </DirectionProvider>
                  </Box>
                );
              } else if (column.type === "multi-select") {
                return (
                  <Box key={column.accessorKey}>
                    <DirectionProvider>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel
                          sx={{ m: "-6px" }}
                          id="demo-multiple-chip-label"
                        >
                          {column.header}
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          sx={{ margin: "auto", width: "100%" }}
                          multiple
                          value={values[column.accessorKey] ?? []}
                          name={column.accessorKey}
                          onChange={(e) => {
                            const name = e.target.name;
                            const value = e.target.value;
                            setValues({
                              ...values,
                              [name]: value,
                            });

                            console.log(values);
                          }}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected?.map((value) => {
                                const selectedLabel = column.list?.find(
                                  (item) => item.value === value
                                )?.label;
                                return (
                                  <Chip key={value} label={t(selectedLabel)} />
                                );
                              })}
                            </Box>
                          )}
                        >
                          {column.list?.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={item.value}
                              dir={theme.direction}
                            >
                              {t(item.label)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </DirectionProvider>
                  </Box>
                );
              } else if (column.type === "date") {
                const dateValue = values[column.accessorKey];
                // debugger;
                return (
                  <Box key={column.accessorKey}>
                    <DirectionProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          sx={{ margin: "auto", width: "100%" }}
                          label={column.header}
                          inputFormat="MM/DD/YYYY"
                          value={dateValue ? dayjs(dateValue) : null}
                          onChange={(e) => {
                            const formattedDate = e
                              ? dayjs(e).format("l")
                              : null;
                            console.log(formattedDate);
                            setValues({
                              ...values,
                              [column.accessorKey]: formattedDate,
                            });
                          }}
                          // renderInput={(params) => (
                          //   <Paper
                          //     {...params}
                          //     sx={{ margin: "auto", width: "100%" }}
                          //   >
                          //     {/* <TextField
                          //       sx={{ margin: "auto", width: "90%" }}
                          //     /> */}
                          //     <IconButton sx={{ p: "10px" }} aria-label="menu">
                          //       <ClearIcon />
                          //     </IconButton>
                          //   </Paper>
                          // )}
                        />
                      </LocalizationProvider>
                    </DirectionProvider>
                  </Box>
                );
                
                
              }else if (column.type === 'time') {
                  const timeValue = values[column.accessorKey];
                  return (
                    <Box key={column.accessorKey}>
                      <DirectionProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            sx={{ margin: "auto", width: "100%" }}
                            label={column.header}
                            value={timeValue ? dayjs(timeValue, 'HH:mm:ss') : null}
                            onChange={(newTime) => {
                              const formattedTime = newTime
                                ? dayjs(newTime).format('HH:mm:ss')
                                : null;
                              console.log(formattedTime);
                              setValues({
                                ...values,
                                [column.accessorKey]: formattedTime,
                              });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </DirectionProvider>
                    </Box>
                  );
              }else if (column.type === "bool") {
                if (values[column.accessorKey] === undefined)
                  setValues({
                    ...values,
                    [column.accessorKey]: values[column.accessorKey] ?? true,
                  });

                return (
                  <Box key={column.accessorKey}>
                    <DirectionProvider>
                      <FormLabel component="legend">
                        {t(column.accessorKey)}
                      </FormLabel>
                      <Checkbox
                        sx={{
                          color: pink[800],
                          "&.Mui-checked": {
                            color: pink[600],
                          },
                        }}
                        checked={values[column.accessorKey] ?? true}
                        defaultValue={true}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            [column.accessorKey]: e.target.checked
                              ? true
                              : false,
                          });
                          setValidationErrors({
                            ...validationErrors,
                            [column.accessorKey]: "",
                          });
                          console.log(values);
                        }}
                      />
                    </DirectionProvider>
                  </Box>
                );
              } else if (column.type === "place") {
                return (
                  <>
                    <Box key={column.accessorKey}>
                      <DirectionProvider>
                        <TextField
                          dir={theme.direction}
                          sx={{ margin: "auto", width: "100%" }}
                          key={column.accessorKey}
                          label={column.header}
                          autoComplete="off"
                          name={column.accessorKey}
                          value={
                            typeof values[column.accessorKey] === "object"
                              ? values[column.accessorKey].name
                              : values[column.accessorKey] || ""
                          }
                          required={column.required}
                          error={!!validationErrors[column.accessorKey]}
                          helperText={validationErrors[column.accessorKey]}
                          onChange={handleChange}
                          InputProps={{
                            startAdornment: (
                              <IconButton
                                onClick={() => {
                                  setMapOpen(true);
                                }}
                              >
                                <AddLocationAltIcon />
                              </IconButton>
                            ),
                          }}
                        />
                      </DirectionProvider>
                    </Box>
                    <AwesomeMapContainer
                      open={mapOpen}
                      handleMapState={handleMapState}
                      callback={(val) => {
                        setValues({ ...values, [column.accessorKey]: val });
                        setValidationErrors({
                          ...validationErrors,
                          [column.accessorKey]: "",
                        });
                        console.log(val);
                        console.log(typeof val === "object");
                      }}
                    />
                  </>
                );
                }else if (column.type === "Map") {
                  return (
                    <>
                      <Box key={column.accessorKey}>
                        <DirectionProvider>
                          <TextField
                            dir={theme.direction}
                            sx={{ margin: "auto", width: "100%" }}
                            key={column.accessorKey}
                            label={column.header}
                            autoComplete="off"
                            name={column.accessorKey}
                            value={
                              typeof values[column.accessorKey] === "object"
                                ? values[column.accessorKey].name
                                : values[column.accessorKey] || ""
                            }
                            required={column.required}
                            error={!!validationErrors[column.accessorKey]}
                            helperText={validationErrors[column.accessorKey]}
                            onChange={handleChange}
                            InputProps={{
                              startAdornment: (
                                <IconButton
                                  onClick={() => {
                                    setMapOpen(true);
                                  }}
                                >
                                  <AddLocationAltIcon />
                                </IconButton>
                              ),
                            }}
                          />
                        </DirectionProvider>
                      </Box>
                      <AwesomeMapContainer
                        open={mapOpen}
                        handleMapState={handleMapState}
                        callback={(val) => {
                          setValues({ ...values, [column.accessorKey]: val.name });
                          setValidationErrors({
                            ...validationErrors,
                            [column.accessorKey]: "",
                          });
                          console.log(val);
                          console.log(typeof val === "object");
                        }}
                      />
                    </>
                  );
              } else {
                return (
                  <Box key={column.accessorKey}>
                    <DirectionProvider>
                      <TextField
                        dir={theme.direction}
                        sx={{ margin: "auto", width: "100%" }}
                        key={column.accessorKey}
                        label={column.header}
                        autoComplete="off"
                        name={column.accessorKey}
                        value={values[column.accessorKey] || ""}
                        required={column.required}
                        error={!!validationErrors[column.accessorKey]}
                        helperText={validationErrors[column.accessorKey]}
                        onChange={handleChange}
                      />
                    </DirectionProvider>
                  </Box>
                );
              }
            })}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: "1.25rem" }}>
        <Button
          onClick={() => {
            onClose();
            setValues({});
            setValidationErrors({});
          }}
        >
          {t("Cancel")}
        </Button>
        <Button
          color={data ? "secondary" : "info"}
          onClick={handleSubmit}
          variant="contained"
        >
          {data ? t("Edit") : t("Create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
AwesomeDialog.defaultProps = {
  // selectionList: [],
};
export default AwesomeDialog;
