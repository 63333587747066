import React from 'react'
import { Box, Button, MenuItem, TextField } from '@mui/material';
import DirectionProvider from '../../providers/DirectionProvider';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useGetAllProvidersQuery } from '../../data/api/provider';
import dayjs from 'dayjs';
import { useState } from 'react';
import formatDate from '../../utils/formatDate';
import { t } from 'i18next';

const ReportFilter = ({onSubmit})=> {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState('accepted');
    const [providerName, setProviderName] = useState('');
    const [validationError, setValidationError] = useState('');

    const statusList = [
        { label: 'pending', value: 'pending' },
        { label: 'accepted', value: 'accepted' },
        { label: 'canceled', value: 'canceled' },
      ];

      const { data , isLoading, isFetching, isError, error } = useGetAllProvidersQuery();

      const handleSubmit = () => {
        if (!startDate || !endDate || !status) {
          setValidationError('Please fill in all required fields.');
        } else {
          setValidationError('');
         const body={
            start_date: formatDate(startDate),
            end_date:formatDate(endDate),
            status,
            provider_id: providerName,
          }
          onSubmit(body);
        }
      };
    
      return (
        <Box display="flex" flexDirection="column" flex="1" justifyContent="center" gap={2}>
          <Box display="flex" flexDirection="row" flex="1" justifyContent="center" gap={1}>
            <Box sx={{ marginLeft: '10%' }}>
              <DirectionProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    sx={{ margin: 'auto', width: '100%' }}
                    label={t("FromDate")}
                    inputFormat="MM/DD/YYYY"
                    value={startDate}
                    onChange={(e) => {
                        setStartDate(dayjs(e).format('l'));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ margin: 'auto', width: '100%' }} />
                    )}
                  />
                </LocalizationProvider>
              </DirectionProvider>
            </Box>
            <Box sx={{ marginLeft: '10%' }}>
              <DirectionProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    sx={{ margin: 'auto', width: '100%' }}
                    label={t("ToDate")}
                    inputFormat="MM/DD/YYYY"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(dayjs(e).format('l'));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ margin: 'auto', width: '100%' }} />
                    )}
                  />
                </LocalizationProvider>
              </DirectionProvider>
            </Box>
            <Box>
              <DirectionProvider>
                <TextField
                  select
                  variant="standard"
                  dir="rtl"
                  sx={{ margin: 'auto', width: '100%' }}
                  label={t('Status')}
                  value={statusList?.find((option) => option.value === status)?.value}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  {statusList?.map((option) => (
                    <MenuItem key={option.value} value={option.value} dir="rtl">
                      {t(option.label)}
                    </MenuItem>
                  ))}
                </TextField>
              </DirectionProvider>
            </Box>
            <Box>
              <DirectionProvider>
              <TextField
                select
                variant="standard"
                dir="rtl"
                sx={{ margin: 'auto', width: '200%' }}
                label={t('provider_name')}
                value={providerName}
                onChange={(e) => setProviderName(e.target.value)}
              >
                <MenuItem value="" >
                  {t('Stores')}
                </MenuItem>
                {data && data.data && data.data.map((provider) => (
                  <MenuItem key={provider.id} value={provider.id}>
                    {provider.company_name}
                  </MenuItem>
                ))}
              </TextField>
            </DirectionProvider>
          </Box>
          </Box>
          <Box  sx={{ display:"flex", flexDirection:"column",justifyContent:"center", width: '20%',margin:"auto", marginTop: '24px !important' }}>
            <Button variant="contained" onClick={() => handleSubmit()}>
              {t("Search")}
            </Button>
            {validationError && (
            <Box sx={{ color: 'red',margin:"auto", }}>
              {validationError}
            </Box>
          )}
          </Box>
    
        </Box>
      );
}

export default ReportFilter


