import { useMemo } from "react";
import AwesomeTable from "../../components/Awesome/AweomeTable";

import { t } from "i18next";
import TableHeaders from "./tableHeaders";
import Loader from "../../components/Common/Loader";
import TableActions from "./tableActions";
import {
  setAddMode,
  setEditMode,
  setItemToEdit,
  setItemToView,
  setViewMode,
} from "../../data/slices/couponSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateCouponMutation,
  useGetAllCouponsQuery,
} from "../../data/api/coupon";
import FullScreenDialog from "../../components/Awesome/FullScreenDialog";
import AwesomeDialog from "../../components/Awesome/AwesomeDialog";
import { useGetProviderSelectListQuery } from "../../data/api/provider";

function CouponsPage() {
  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError, error } =
    useGetAllCouponsQuery();
  const { itemToView, viewMode, addMode, deleteMode, editMode, itemToEdit } =
    useSelector((state) => state.coupon);

  const [createCoupon, createCouponResult] = useCreateCouponMutation();
  const providers = useGetProviderSelectListQuery();

  const columns = useMemo(
    () =>
      TableHeaders.map((header) => ({
        ...header,
        header: t(header.header),
      })),
    []
  );
  const setCreateOptions = () => {
    dispatch(setAddMode(true));
  };
  const onSubmit = (body) => {
    createCoupon(body);
  };
  const typeList = [
    { label: t("fixed"), value: "fixed" },
    { label: t("percentage"), value: "percentage" },
  ];
  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }

  return (
    <>
      {data && (
        <AwesomeTable
          columns={columns}
          data={data.data}
          showActions={false}
          RowActions={TableActions}
          setCreateOptions={setCreateOptions}
          showTopActions
        />
      )}

      <AwesomeDialog
        columns={[
          { accessorKey: "code", required: true, header: t("Code") },
          {
            accessorKey: "provider_id",
            type: "select",
            required: true,
            header: t("Provider"),
            list: providers.data?.data,
          },
          {
            accessorKey: "type",
            type: "select",
            required: true,
            header: t("type"),
            list: typeList,
          },
          { accessorKey: "amount", required: true, header: t("Value") },
          {
            accessorKey: "max_activation",
            required: false,
            header: t("MaxActivation"),
          },
          {
            accessorKey: "expiry_date",
            required: false,
            type: "date",
            header: t("ExpiryDate"),
          },
        ]}
        open={addMode}
        onClose={() => {
          dispatch(setEditMode(false));
          dispatch(setAddMode(false));
          dispatch(setItemToEdit(null));
        }}
        onSubmit={onSubmit}
      />
    </>
  );
}

export default CouponsPage;
