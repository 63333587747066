import { Alert, AlertTitle, Box, Typography } from '@mui/material'
import { t } from 'i18next'
import { PropagateLoader } from 'react-spinners'

const Loader = ({ isError = false, error }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '80%',
      }}
    >
      {isError ? <Alert severity="error">
        <AlertTitle>{t("Error")}</AlertTitle>
        {error}
      </Alert>: <PropagateLoader color="#36d7b7"/>}
    </Box>
  )
}

export default Loader
