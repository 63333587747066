import { tokens } from "../../../theme";

const convertToChartData = (invoicesDailyChart) => {
  if (invoicesDailyChart) {
    const chartData = [
      {
        id: "invoices",
        color: tokens("dark").greenAccent[500],
        data: invoicesDailyChart?.map((item) => ({
          x: item.x.toLowerCase(),
          y: item.y,
        })),
      },
    ];

    return chartData;
  }
};

export default convertToChartData;
