import { Box, Button, Skeleton, Typography, useTheme } from "@mui/material";

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import StatBox from "../../../components/StatBox";
import { FireTruck, Person, ShoppingBasket } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DashboardSkeleton from "./dashboardSkeleton";
import LineChart from "./lineChart"
import convertToChartData from "./convertChartData";
import { t } from "i18next";
import { useGetHomeDataQuery } from "../../data/api/home";



const NewDashboard = () => {




  const { data, isLoading, isFetching, isError, error } = useGetHomeDataQuery();


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (isLoading) {
    return(

      <DashboardSkeleton/>
    )

  }
  
  return (
    <Box m="20px" height="80%" overflow="auto" marginLeft="0px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("Dashboard")}  />


      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={data?.vouchers}
            subtitle={t("Vouchers")}
      
            icon={
              <FireTruck
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={data?.providers}
            subtitle={t("Providers")}
           
            icon={
              <AccountBalanceIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={data?.clients}
            subtitle={t("Clients")}
        
            icon={
              <Person
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={data?.orders}
            subtitle={t("Orders")}
          
            icon={
              <ShoppingBasket
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
             
              {t("Invoices")}

              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data?.ordersTotalPrice) }
              </Typography>
            </Box>
        
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            {data?.invoices_daily_chart && <LineChart data={convertToChartData( data?.invoices_daily_chart)} isDashboard={true} />}
          </Box>
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              
              {t("RecentOrders")}
            </Typography>
          </Box>
          {data?.recentOrders?.map((order, i) => (
          <Box
            key={`${order.order_number}-${i}`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="15px"
          >
            <Box flex={1}>
              <Typography
                color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
              >
                {order.order_number}
              </Typography>
              <Typography color={colors.grey[100]}>
                {order.provider_name}
              </Typography>
            </Box>
            <Box dir="ltr" flex={1} color={colors.grey[100]} marginLeft={"15px"} alignContent={"center"}>
              {order.created_at}
            </Box>
            <Box flex={0.5}>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {order.total_cost}
              </Box>
            </Box>
          </Box>
        ))}

        </Box>

        {/* <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              {t("ActiveDrivers")}
            </Typography>
          </Box>
          {data?.availableDrivers?.map((driver, i) => (
            <Box
              key={`${driver.phone}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box flex={1}>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {driver.name}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {driver.truck_type}
                </Typography>
              </Box>
              <Box flex={1} color={colors.grey[100]}>
                {driver.phone}
              </Box>
              <Box flex={1} color={colors.grey[100]}>
                {driver.address}
              </Box>
            </Box>
          ))}

        </Box> */}
      </Box>
  
    </Box>
  );
};

export default NewDashboard;
