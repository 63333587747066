import html2pdf from "html2pdf.js";

const exportToPDF = (id) => {
  const input = document.getElementsByClassName("MuiTableContainer-root")[0]; // Make sure to give this ID to the root element of the component's JSX
  const input2 = document.getElementById(id); // Make sure to give this ID to the root element of the component's JSX
  const container = document.createElement("div"); // Create a container div
  container.appendChild(input); // Append input to the container
  container.appendChild(input2);

  const pdfOptions = {
    margin: [0, 0, 10, 0],
    filename: "offers-and-awareds.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: {},
    jsPDF: { unit: "mm", format: "a2", orientation: "portrait" },
  };

  html2pdf().from(container).set(pdfOptions).save();
};

export default exportToPDF;
