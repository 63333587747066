import { useMemo } from "react";
import AwesomeTable from "../../components/Awesome/AweomeTable";

import { t } from "i18next";
import TableHeaders from "./tableHeaders";
import {
  useCreateAdminMutation,
  useDeleteAdminMutation,
  useGetAllAdminsQuery,
} from "../../data/api/admin";
import Loader from "../../components/Common/Loader";
import TableActions from "./tableActions";
import {
  setAddMode,
  setDeleteMode,
  setEditMode,
  setItemToDelete,
  setItemToEdit,
  setItemToView,
  setViewMode,
} from "../../data/slices/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import FullScreenDialog from "../../components/Awesome/FullScreenDialog";
import AwesomeDialog from "../../components/Awesome/AwesomeDialog";
import mapToFormData from "../../utils/mapToFormData";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog";

function AdminsPage() {
  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError, error, ...props } =
    useGetAllAdminsQuery();
  const {
    itemToView,
    viewMode,
    addMode,
    deleteMode,
    editMode,
    itemToEdit,
    itemToDelete,
  } = useSelector((state) => state.admin);
  const [createAdmin, createAdminResult] = useCreateAdminMutation();

  const [deleteAdmin, deleteAdminResult] = useDeleteAdminMutation();

  const columns = useMemo(
    () =>
      TableHeaders.map((header) => ({
        ...header,
        header: t(header.header),
      })),
    []
  );
  if (createAdminResult.isSuccess) {
    createAdminResult.reset();
    props.refetch();
  }
  if (deleteAdminResult.isSuccess) {
    deleteAdminResult.reset();
    props.refetch();
  }

  const setCreateOptions = () => {
    dispatch(setAddMode(true));
  };
  const onSubmit = (body) => {
    createAdmin(body);
  };
  const adminTypeList = [
    { label: "super", value: "super" },
    { label: "admin", value: "admin" },
  ];

  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }

  return (
    <>
      {data && (
        <AwesomeTable
          columns={columns}
          data={data.data}
          showActions
          RowActions={TableActions}
          setCreateOptions={setCreateOptions}
          showTopActions
        />
      )}

      <AwesomeDialog
        columns={[
          { accessorKey: "name", header: t("Name") },
          { accessorKey: "email", header: t("Email") },
          { accessorKey: "password", header: t("Password") },
          {
            accessorKey: "type",
            type: "select",
            header: t("type"),
            list: adminTypeList,
          },
        ]}
        open={addMode}
        onClose={() => {
          dispatch(setAddMode(false));
        }}
        onSubmit={onSubmit}
      />
      <ConfirmationDialog
        open={deleteMode}
        onClose={() => {
          dispatch(setItemToDelete(null));
          dispatch(setDeleteMode(false));
        }}
        onConfirm={() => {
          deleteAdmin(itemToDelete.id);
        }}
        message={t("DeleteConfirmation")}
      />
    </>
  );
}

export default AdminsPage;
