
import { useMemo } from 'react';
import AwesomeTable from '../../components/Awesome/AweomeTable';

import { t } from 'i18next';
import DirectionProvider from '../../providers/DirectionProvider';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import TableHeaders from './tableHeaders';
import { useState, useEffect } from 'react';
import { useAddCustomSlideMutation, useDeleteCustomSlideMutation, useEditCustomSlideMutation, useGetAllCustomSlidesQuery, usePinVoucherToCustomSlideMutation, useRemovePinnedVoucherMutation } from '../../data/api/customSlide';
import Loader from '../../components/Common/Loader';
import TableActions from './tableActions';
import { setAddMode, setDeleteMode, setEditMode, setItemToDelete, setItemToEdit, setItemToView, setViewMode, setItemToPin, setPinVoucher, setItemToPinView, setViewPin, setItemToRemovePin, setRemovePinVoucher} from '../../data/slices/customSlideSlice';
import { useDispatch, useSelector } from 'react-redux';
import FullScreenDialog from '../../components/Awesome/FullScreenDialog';
import AwesomeDialog from '../../components/Awesome/AwesomeDialog';
import mapToFormData from '../../utils/mapToFormData';
import ConfirmationDialog from '../../components/Dialogs/ConfirmationDialog';

function CustomSlidePage() {


  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError, error } = useGetAllCustomSlidesQuery();
  const {itemToView,viewMode,addMode,deleteMode,editMode,itemToEdit,itemToDelete, itemToPin, pinVoucher, viewPin, itemToPinView,  itemToRemovePin, removePinVoucher } = useSelector(state=> state.customSlide);
  const [createCustomSlide, createCustomSlideResult] = useAddCustomSlideMutation();
  const [editCustomSlide, editCustomSlideResult] = useEditCustomSlideMutation();
  const [deleteCustomSlide, deleteCustomSlideResult] = useDeleteCustomSlideMutation();
  const [addVoucherPin, addVoucherPinResult] =  usePinVoucherToCustomSlideMutation();
  const [ deletePinVoucher, deleteVoucherPinResult ] = useRemovePinnedVoucherMutation();

  const columns = useMemo(
    () =>  TableHeaders.map((header) => ({
      ...header,
      header: t(header.header), 
    })),
    [],
  );

  const setCreateOptions = ()=>{
    dispatch(setAddMode(true))
  }
   const onSubmit = (body) => {
    body = mapToFormData(body);
    console.log(body)
    if (addMode) createCustomSlide(body);
    if (editMode && itemToEdit) editCustomSlide({ body, id: itemToEdit.id });
  };


  if (isLoading || isFetching || isError) {
    return <Loader isError={isError} error={error?.message} />;
  }

    
  const onPin = (body) => {
    body.custom_slide_id = itemToPin.id;
    body = mapToFormData(body);
    addVoucherPin(body);
  };

  const onRemovePin = (body) => {
    body.custom_slide_id = itemToRemovePin.id;
    body = mapToFormData(body);
    deletePinVoucher(body);
  }

  return (
    <>
    {data && <AwesomeTable 
    columns={columns}
    data={data.data}
    showActions
    RowActions={TableActions}
    setCreateOptions={setCreateOptions}
    showTopActions 
    />}

<FullScreenDialog
    
    data = {itemToView}
    columns={[  
      { accessorKey: 'name', header: t('Name') },
      { accessorKey: 'vouchers', type: "select-list", header: t('Vouchers'), list: itemToView?.vouchers },


    ]}
    open={viewMode}
    onClose={() => {
      dispatch(setItemToView(null))
      dispatch(setViewMode(false))
    }}
    />


      <AwesomeDialog
      data = {itemToEdit}
      columns={[  
        { accessorKey: 'name',required:true, header: t('Name') },
      ]}
      open={addMode || editMode}
      onClose={() => {
    
      dispatch(setEditMode(false))
      dispatch(setAddMode(false))
      dispatch(setItemToEdit(null))

      }}
      onSubmit={onSubmit}
    />
      <ConfirmationDialog
      open={deleteMode}
      onClose={()=>{
      dispatch(setItemToDelete(null))
      dispatch(setDeleteMode(false))

      }}
      onConfirm={()=>{
        deleteCustomSlide({id:itemToDelete.id})
      }}
      message={t("DeleteConfirmation")}
      />
      <AwesomeDialog
        columns={[
          {
            accessorKey: "voucher_id",
            required: true,
            type: "select",
            header: t("ChosseVoucherToPin"),
            list: itemToPin && itemToPin.vouchers_unpinned ? itemToPin.vouchers_unpinned : [{ label: "Voucher not found", value: "not-found" }],
            valueAccessor: (option) => option.value,
          },
        ]}
        open={pinVoucher}
        onClose={() => {
          dispatch(setItemToPin(null));
          dispatch(setPinVoucher(false));
        }}
        onSubmit={onPin}
      />

    <FullScreenDialog
      data = {itemToPinView}
      columns={[  
        { accessorKey: 'name', header: t('Name') },
        { accessorKey: 'vouchers_pinned', type: "select-list", header: t('Vouchers'), list: itemToPinView?.vouchers_pinned },
      ]}
      open={viewPin}
      onClose={() => {
        dispatch(setItemToPinView(null))
        dispatch(setViewPin(false))
      }}
      />
      <AwesomeDialog
        columns={[
          {
            accessorKey: "voucher_id",
            required: true,
            type: "select",
            header: t("ChooseRemovePinConfirmation"),
            list: itemToRemovePin && itemToRemovePin.vouchers_pinned ? itemToRemovePin.vouchers_pinned : [{ label: "Voucher not found", value: "not-found" }],
            valueAccessor: (option) => option.value,
          },
        ]}
        open={removePinVoucher}
        onClose={() => {
          dispatch(setItemToRemovePin(null));
          dispatch(setRemovePinVoucher(false));
        }}
        onSubmit = {onRemovePin}
      />

    </>
  )
}

export default CustomSlidePage