import { Chip, IconButton, Switch, Tooltip } from "@mui/material";
import { t } from "i18next";
import { Check, Close, NotificationAdd } from "@mui/icons-material";
import { useToggleActiveClientMutation } from "../../data/api/client";
import ImageContainer from "../../components/Awesome/ImageContainer";
import { useDispatch } from "react-redux";
import { blue } from "@mui/material/colors";
import { setItemToNotify, setNotifyMode } from "../../data/slices/clientSlice";
const TableImageCell = ({ row }) => {
  return <ImageContainer url={row.original.profile_image_url} />;
};
const TableToggleCell = ({ row }) => {
  const [selectedClient, selectedClientResult] =
    useToggleActiveClientMutation();

  const handleChange = () => {
    selectedClient({ id: row.original.id });
  };

  return (
    <Switch
      color="secondary"
      checked={row.original.is_blocked}
      onChange={handleChange}
    />
  );
};
const NotifyClient = ({ row }) => {
  const dispatch = useDispatch();

  return (
    <Tooltip arrow placement="left" title={t("Notify")}>
      <IconButton
        sx={{
          color: blue[800],
        }}
        onClick={() => {
          dispatch(setItemToNotify(row.original));
          dispatch(setNotifyMode(true));
        }}
      >
        <NotificationAdd />
      </IconButton>
    </Tooltip>
  );
};
const TableHeaders = [
  {
    accessorKey: "name",
    header: "Name",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "email",
    header: "Email",
    type: "text",
    required: false,
    size: 140,
  },

  {
    accessorKey: "phone",
    header: "Phone",
    type: "text",
    required: false,
    size: 140,
  },

  {
    accessorKey: "government",
    header: "Government",
    type: "text",
    required: false,
    size: 140,
  },
  {
    accessorKey: "profile_image_url",
    header: "Image",
    type: "text",
    required: false,
    size: 140,
    Cell: TableImageCell,
  },

  {
    accessorKey: "status",
    header: "IsBlocked",
    type: "text",
    required: false,
    size: 140,
    Cell: TableToggleCell,
  },
  {
    accessorKey: "notify",
    header: "notify",
    type: "text",
    required: false,
    size: 140,
    Cell: NotifyClient,
  },
];
export default TableHeaders;
